import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Share from "../../assets/media/share.svg";
import axios from "axios";
import top from "../../assets/media/top.svg";


const About = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.multigrad.in/secure/contributor`,
          {}
        );
        setData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  const [userMessage, setUserMessage] = useState([]);
  const handleMessageSend = () => {
    const recipient = "multigradapp@gmail.com"; // Replace with the actual recipient email address
    const subject = "Contributor"; // Replace with the desired subject
    const message = userMessage; // Replace with the default message

    // Generate the mailto: URL
    const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;

    // Open the user's default email client with the pre-filled mail
    window.location.href = mailtoUrl;
  };
  const handleInputChange = (event) => {
    setUserMessage(event.target.value);
  };
  return (
    <>
      <div className="mg-job-container">
        <div className="cb-page">
          
          <h1>
            "We are on the mission to digitalize all organizations across India!
          </h1>

          <form>
            <h2>Our Founders</h2>
            <div className="mg-peoples">
              <a>
                <div className="mg-profiles">
                  <img
                    src={`https://api.multigrad.in/open/media/?file=1695584577430.png`}
                    alt={`Aman Sharma`}
                  />
                  <p>Full-Stack Developer</p>
                  <button disabled>Aman Sharma</button>
                </div>
              </a>
            </div>
            <div className="mg-peoples">
              <a>
                <div className="mg-profiles">
                  <img
                    src={`https://api.multigrad.in/open/media/?file=1695584667668.png`}
                    alt={`Abhishek Yadav`}
                  />
                  <p>Backend Developer</p>
                  <button disabled>Abhishek Yadav</button>
                </div>
              </a>
            </div>
            <div className="mg-peoples">
              <a>
                <div className="mg-profiles">
                  <img
                    src={`https://api.multigrad.in/open/media/?file=1695584713183.png`}
                    alt={`Sandeep Shakya`}
                  />
                  <p>Co-Founder & Director</p>
                  <button disabled>Sandeep Shakya </button>
                </div>
              </a>
            </div>
            <div className="mg-peoples">
              <a>
                <div className="mg-profiles">
                  <img
                    src={`https://api.multigrad.in/open/media/?file=1696525312270.png`}
                    alt={`Krishna Mohan Yadav`}
                  />
                  <p>Co-Founder & Director</p>
                  <button disabled>Krishna Mohan Yadav </button>
                </div>
              </a>
            </div>

            <h2>Founding Interns</h2>

            <div className="mg-peoples">
              <a>
                <div className="mg-profiles">
                  <img
                    src={`https://api.multigrad.in/open/media/?file=1690284864386.jpeg`}
                    alt={`Aayush Shrivastava`}
                  />
                  <p>Full-Stack Developer</p>
                  <button disabled>Aayush Shrivastava</button>
                </div>
              </a>
            </div>
            <div className="mg-peoples">
              <a>
                <div className="mg-profiles">
                  <img
                    src={`https://api.multigrad.in/open/media/?file=1690286708890.jpg`}
                    alt={`Mohit Kumar Saroj`}
                  />
                  <p>Full-Stack Developer</p>
                  <button disabled>Mohit Kumar Saroj</button>
                </div>
              </a>
            </div>
            <div className="mg-peoples">
              <a>
                <div className="mg-profiles">
                  <img
                    src={`https://api.multigrad.in/open/media/?file=1690286798010.jpg`}
                    alt={`Vishwajeet`}
                  />
                  <p>Full-Stack Developer</p>
                  <button disabled>Vishwajeet</button>
                </div>
              </a>
            </div>
            <h2>Contributors</h2>
            {data.map((peoples) => (
            
                <div className="mg-profiles">
                  <img src={peoples.profilePic} alt={peoples.fullName} />
                  <p>{peoples.role}</p>
                  <button disabled>
                    {peoples.fullName}{" "}
                    <img src={Share} alt="Share" id="share" />
                  </button>
                </div>
             
            ))}
          </form>
        </div>
      </div>
    </>
  );
}

export default About;
