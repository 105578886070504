import React from 'react';
import Rocket from '../../../assets/2.0/media/rocket.svg';
const Start = () => {
    const handleStartFree = () => {
        window.open("/login", '_blank');
    };
    const JoinComunity = () => {
        window.open("https://calendly.com/amnshrma/30min?back=1&month=2024-05",'_blank');
    };


  return (
   <>
     <div className='footer' >
            <div className="highlighted">
                <div className='container'>
                    <div className='flex-mobile flex-row'>
                    <div className='mg-60'>
                    <h1><span id="white">Ready to build digital organisation CRM?</span></h1>
                   
                </div>
                <div className='mg-40'>
                    <img src={Rocket} />
                </div>      
                </div>
                </div>
            </div>
         </div>
   </>
  );
};

export default Start;
