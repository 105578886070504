import Header from "./screens/commonScreens/header";
import Footer from "./screens/commonScreens/footer";
import "./assets/css/App.css";
import "./assets/css/Dark.css";
import "./assets/css/hackathon.css";
import "./assets/css/mobile.css";
import "./assets/css/Dash2.css";
import "./assets/2.0/css/style.css"
import Portfolio from "./screens/orgPortfolio/Portfolio";
import Homescreen from "./screens/withoutAuth/homescreen";
import Discover from "./screens/withoutAuth/discover";
import About from "./screens/withoutAuth/about";
import Privacy from "./screens/withoutAuth/privacy";
import Terms from "./screens/withoutAuth/terms";
import Support from "./screens/withoutAuth/support";
import Listings from "./screens/withoutAuth/owners/listing";
import Settings from "./screens/dashboard/settings";
import Profile from "./screens/dashboard/profile";
import Dashboard from "./screens/dashboard/dashboard";
import Course from "./screens/courseManagement/AllListings";
import EditCourse from "./screens/courseManagement/editcourse";
import PublishJobs from "./screens/hrPortal/PublishJobs";
import PublishCourse from "./screens/courseManagement/PublishCourses";
import Hackathons from "./screens/hackathons/allListings";
import JobEdit from "./screens/hrPortal/JobEdit";
import HackathonEdit from "./screens/hackathons/editHackathon";
import AllJobs from "./screens/hrPortal/AllJobs";
import CourseInner from "./screens/courses/create";
import Roadmap from "./screens/roadmap/all";
import RoadmapCreate from "./screens/roadmap/create";
import RoadmapEdit from "./screens/roadmap/edit";
import Peoples from "./screens/peoples/index";
import UserProfile from "./screens/publicProfile/index";
import Signup from "./screens/authScreens/NewAccount";
import OnBoard from "./screens/authScreens/OnBoard";
import PublishH from "./screens/hackathons/publishHackathon";
import Community from "./screens/community/listings";
import CommunityCreate from "./screens/community/createCommunity";
import MessagePage from "./screens/messenger/MessengerPage";
import EditOrganisation from "./screens/organisation/edit";
import Sidebar from "./screens/sidebar/Index";

import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useEffect } from "react";

import Events from "./screens/events/allListings";
import CreateEvent from "./screens/events/publishEvents";
import Header2 from "./screens/2.0/common/Header";
import Footer2 from "./screens/2.0/common/Footer";
import Home2 from "./screens/2.0/home/index"
import { JobProvider } from "./screens/2.0/context/JobContext";
import Roadmap2 from "./screens/2.0/home/Roadmap";
import Mentorship2 from "./screens/2.0/home/mentorship";
import Leader from "./screens/2.0/home/Leader";
import Privacy2 from "./screens/2.0/home/Privacy";
import Terms2 from "./screens/2.0/home/Terms";
import Clubs2 from "./screens/2.0/home/Clubs";
import Internship2 from "./screens/2.0/home/internship";
import Pricing2 from "./screens/2.0/home/internship";
import NotFoud from "./screens/2.0/home/NotFound";


function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.slice(1).charAt(0).toUpperCase()) {
      document.title =
        location.pathname.slice(1).charAt(0).toUpperCase() +
        location.pathname.slice(2) +
        " | Multigrad";
    } else {
      document.title = " Multigrad | Connect with your circle & interest!";
    }
  }, [location]);

  return (
    <>
    
      <Routes>

        <Route path="/about-us" element={<> <Header /> <About /></>} />
        <Route path="/privacy" element={<> <Header /> <Privacy /></>} />
        <Route path="/support" element={<> <Header /> <Support /></>} />
        <Route path="/*" element={<> <Header2 /> <NotFoud /><Footer2/></>} />
  
        

           
            <Route path="/" element={<> <Header2 />
         <Home2 />
         <Footer2 /> </>} />
         <Route path="/pricing" element={<> <Header2 /> <JobProvider><Pricing2 /> </JobProvider><Footer2 /> </>} />
         <Route path="/roadmaps" element={<> <Header2 /><Roadmap2 /><Footer2 /> </>} />
         <Route path="/mentorship" element={<> <Header2 /><Mentorship2 /><Footer2 /> </>} />
         <Route path="/clubs" element={<> <Header2 /><Clubs2 /><Footer2 /> </>} />
         <Route path="/become-a-leader" element={<> <Header2 /><Leader /><Footer2 /> </>} />
         <Route path="/privacy-policy" element={<> <Header2 /><Privacy2 /><Footer2 /> </>} />
         <Route path="/terms-and-condition" element={<> <Header2 /><Terms2 /><Footer2 /> </>} />

     
        <Route
          path="/dashboard/organisation/edit"
          element={<>  <Header /> <EditOrganisation /></>}
        />
      </Routes>
      
    </>
  );
}

export default App;