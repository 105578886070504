import { FcGoogle } from "react-icons/fc";
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Navbar, Dropdown } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Login from "../authScreens/Login";
import Signup from "../authScreens/Signup";
import { FaYoutube } from "react-icons/fa6";
import { RiAppStoreFill } from "react-icons/ri";
import { HiOutlineLogout } from "react-icons/hi";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { BiSolidCategory } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { BsFillPeopleFill } from "react-icons/bs";
import Org from "../../assets/media/org.svg";
import Logo from "../../assets/media/mg-logo.svg";
import LogoLogin from "../../assets/media/logo.svg";
import { BiHomeAlt2 } from "react-icons/bi";
import { GrInbox, GrHomeRounded } from "react-icons/gr";
import { BsRocketTakeoffFill, BsPlus, BsBook } from "react-icons/bs";
import { GoGoal, GoInbox } from "react-icons/go";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
function HeaderOld() {
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState();
  const location = useLocation();

  useEffect(() => {
    userDetails();
    if (location.pathname === "/privacy" || "/terms" || "/support") {
    } else if (userToken === "" || userToken === null) {
      navigate("/");
    }
  }, []);
  const [details, setDetails] = useState("");
  const [show, setShow] = useState(false);

  function handleSignin() {
    setShow(true);
  }

  const [showSignup, setShowSignup] = useState(false);
  function handleSignup() {
    navigate("/signup");
  }

  function handleHome() {
    navigate("/");
    window.location.reload(false);
  }

  function handleDashboard() {
    navigate("/mycollege/dashboard");
  }

  function handleLogout() {
    localStorage.removeItem("userToken");
    navigate("/");
  }

  function handleAllCourses() {
    navigate("/courses");
  }

  function handleJobs() {
    navigate("/jobs/create");
  }
  function handleHackathons() {
    navigate("/hackathons/create");
  }

  function handleCommunity() {
    navigate("/community/create");
  }

  function handleAboutus() {
    navigate("/about-us");
  }
  function handleAllJobs() {
    navigate("/jobs");
  }

  function handleAllHackathons() {
    navigate("/hackathons");
  }
  function handleCourses() {
    navigate("/course/create");
  }

  function handleRoadMapCreate() {
    navigate("/roadmap/create");
  }

  function handleSettings() {
    navigate("/settings");
  }

  function handlePeoples() {
    navigate("/peoples");
  }

  const userDetails = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/auth/user",
      headers: {
        authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setDetails(response.data.data[0]);
        setUserProfile(response.data.data[0].userRole)
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleMessages() {
    navigate("/messaging");
  }
  const handleBusiness = () => {
    window.open("https://tally.so/r/nGp7v2", "_blank");
  };

  const handleApp = () => {
    window.open("https://play.google.com/store/apps/details?id=com.multigrad.app", "_blank");
  };

  const handleEvents = () => {
    navigate("/events");
  };
  const handleEventsCreate = () =>{
    navigate("/events/create");
  }

  const handleOrganisation = () => {
    navigate("/dashboard/organisation/edit");
  };

  return (
    <>
      <Navbar bg="" expand="lg">
        <Container id="navTop">
          <Navbar.Brand href="">
            {localStorage.getItem("userToken") === null ? (
              <div className="multigradLogo" onClick={handleHome}>
                <img src={Logo} alt={`Multigrad App`} />
              </div>
            ) : (
              <div className="multigradLogoLog" onClick={handleDashboard}>
                <img src={LogoLogin} alt={`Multigrad App`} />
              </div>
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            {localStorage.getItem("userToken") === null ? (
              <>
                <Nav className="me-auto">
                <Nav className="me-auto auth">
               
              </Nav>
                

                 
                </Nav>
              </>
            ) : (
             
              <>
               {userProfile != 'user' &&
              <Nav className="me-auto auth">
             
                
                
               
                <Nav.Link onClick={handleDashboard}>
                  <img src={Org} id="icon" /> Organisation
                </Nav.Link>
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                    Create
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleJobs}>
                      Job or Internship
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleHackathons}>
                      Hackathon
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleEventsCreate}>
                      Events
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                    My Listings
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleAllJobs}>
                      Job or Internship
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleAllHackathons}>
                      Hackathon
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleEvents}>
                      Events
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Nav.Link onClick={handleMessages}>Messages</Nav.Link>
                <Nav.Link onClick={handlePeoples}>Students</Nav.Link>
              </Nav>
}


{userProfile == 'user' &&
              <Nav className="me-auto auth">
             
                
                
               
                <Nav.Link onClick={handleDashboard}>
                  <img src={Org} id="icon" /> My College
                </Nav.Link>
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                    Create
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleJobs}>
                      Job or Internship
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleHackathons}>
                      Hackathon
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleEventsCreate}>
                      Events
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                    My Listings
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleAllJobs}>
                      Job or Internship
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleAllHackathons}>
                      Hackathon
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleEvents}>
                      Events
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Nav.Link onClick={handleMessages}>Messages</Nav.Link>
                <Nav.Link onClick={handlePeoples}>Students</Nav.Link>
              </Nav>
}


              </>


            )}
            {show && <Login setShow={setShow} show={show} />}
            {showSignup && (
              <Signup setShowSignup={setShowSignup} show={showSignup} />
            )}

            {localStorage.getItem("userToken") === null ? (
              <>
               
                <p onClick={handleSignup} id="signup">
                 Digital Organisation
                </p>
              </>
            ) : (
              <>
                <img src={details.userProfile} className="userProfile" />
                <NavDropdown title={details.fullName} id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={handleOrganisation}>
                    <IoMdSettings id="icon" />
                    Organisation
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleSettings}>
                    <IoMdSettings id="icon" />
                    Settings
                  </NavDropdown.Item>

                  <NavDropdown.Divider />
                  <NavDropdown.Item href="" onClick={handleLogout}>
                    <HiOutlineLogout id="icon" /> Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </Navbar.Collapse>
          
        </Container>
        
      </Navbar>


      
    </>
  );
}

export default HeaderOld;
