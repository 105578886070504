import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import Swal from "sweetalert2";
import { AiOutlineMail } from "react-icons/ai";
import Signup from "../authScreens/Signup";
import withReactContent from "sweetalert2-react-content";
import QRApp from "../../assets/media/qrapp.png";
const Login = (props) => {
  const MySwal = withReactContent(Swal);

  const handleClose = () => {
    props.setShow(false);
  };

  const [validated, setValidated] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [formData, setFormData] = useState({});
  function handleSignup() {
    setShowSignup(true);
  }

  const loginwithGoogle = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );
        let data = JSON.stringify({
          email: res.data.email,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://api.multigrad.in/secure/google-login",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            if (response.data.status === true) {
              localStorage.setItem("userToken", response.data.userToken);
              window.location.href = "/dashboard";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        console.log(err);
        MySwal.fire({
          title: <strong>Good job!</strong>,
          html: <i>You clicked the button!</i>,
          icon: "success",
        });
      }
    },
  });

  return (
    <div className="custom-login">
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header className="border-0">
          <Modal.Title>Login or Download the app</Modal.Title>
        </Modal.Header>
        <div>
          <Modal.Body className="modalBody">
            {/* <Button className="col-12 withGoogle " onClick={loginwithGoogle}>
              <img alt="" className="googleFont" />
              <FcGoogle id="gIcon" /> Contine With Google
            </Button> */}
            <Button
              variant="danger"
              type="submit"
              className="col-12 customPopupBtn"
              id="margin"
              onClick={handleSignup}
            >
             Organisation Login
            </Button>

            <p id="qr">Or scan qr and download app.</p>
            <div className="qrDiv">
              <img src={QRApp} id="qrimg" />
            </div>

            <p id="privacy">
              This site is protected by reCAPTCHA, Google Privacy Policy and
              Terms of Service apply.
            </p>
          </Modal.Body>
        </div>
      </Modal>
      {showSignup && <Signup setShowSignup={setShowSignup} show={showSignup} />}
    </div>
  );
};

export default Login;
