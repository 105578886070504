import React from 'react';
import Users from '../../../assets/2.0/media/userssvg.svg';
import CM from '../../../assets/2.0/media/cm.svg';
import Start from '../common/Start';
import ButtonTop from '../common/Button';
import Upcoming2 from '../common/Upcoming';
const Home2 = () => {
  return (
    <div className='home-container'>
        <div className='container line-container'>
            <div className='tagline'>
                <h1>"Unite the Minds<span id="white"> Join Campus Connect Today!"</span></h1>
                <p>Powerful, Flexible and result driven,
Multigrad to build the exact CRM your organisation needs.</p>
               <ButtonTop />
                <div className='btn-two'>
                   
                    <p>1,000+ organisation across india using multigrad</p>
                </div>
                <div className='flex-box flex-row'>
                    <div className='boxes'>
                        <h3>Campus connect</h3>
                        <p>Seamlessly connect with students, faculty, and administrators for streamlined collaboration.</p>
                    </div>
                    <div className='boxes'>
                        <h3>100% control</h3>
                        <p>Total control from moderating chat groups to hosting events or hackathons.</p>
                    </div>
                    <div className='boxes'>
                       <h3>Fully customizable</h3>
                       <p>Create events, communities, and spaces unique to your institution and goals.</p>
                    </div>
                    <div className='boxes'>
                    <h3>Own Website & App</h3>
                    <p> Your Gateway to Seamless Connectivity with Our Website & App!</p>
                    </div>
                </div>
            </div>
        </div>
      

        <div className='container line-container'>
            <div className='tagline'>
                <h1>Become a <span id="white">Digital Campus"</span></h1>
                <p>Manage your organisation over the internet and become a part of digital campus</p>
              
            </div>
          
              
        </div>
        
        
    </div>
  );
};

export default Home2;
