import React from "react";
import Container from "react-bootstrap/Container";
import Users from '../../../assets/2.0/media/userssvg.svg';
import ButtonTop from "../common/Button";
function NotFoud() {
  return (
    <div className='home-container'>
         <div className='container line-container'>
            <div className='tagline'>
                <h1><span id="white">404</span></h1>
               
            </div>
        </div>

       

      </div>
    
  );
}

export default NotFoud;
