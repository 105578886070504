// JobContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const JobContext = createContext();

const JobProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.multigrad.in/open/job/');
        setJobs(response.data.data);
        setPagination(response.totalPages);
      } catch (error) {
        console.error('Error fetching job data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <JobContext.Provider value={jobs}>
      {children}
    </JobContext.Provider>
  );
};

export { JobContext, JobProvider };
