import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import { Container } from "react-bootstrap";



const Support = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const userToken = localStorage.getItem("userToken");

  const [formData, setFormData] = useState({
    emailAddress: "",
    fullName: "",
    userFeedback: "",
  });


  const handleSubmit = (e) => {
    e.preventDefault();
    let data = JSON.stringify({
      "emailAddress": formData.emailAddress,
      "fullName": formData.fullName,
      "userFeedback": formData.userFeedback
    });
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.multigrad.in/secure/feedback',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setFormData({
          "emailAddress": "",
          "fullName": "",
          "userFeedback": ""
        })
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container>
      <div className="mgTagline mb-5">
        <div className="tagLeftPrivacy mobile ">
          <h2>Customer Support</h2>
        </div>
      </div>
      <div className="mg-job-container ">
        <div className="mg-job-second-section">
          <div className="mg-job-form">
            <form onSubmit={handleSubmit}>
              <div className="job-input-sections">
                <label>Full Name*</label>
                <input
                  type="text"
                  placeholder="Eg: Multigrad"
                  value={formData.fullName}
                  name="fullName"
                  onChange={(e) =>
                    handleInputChange(e)
                  }
                  required
                />
              </div>
              <div className="job-input-sections">
                <label>Email*</label>
                <input
                  type="email"
                  placeholder="Eg: someone@multigrad.com"
                  value={formData.emailAddress}
                  name="emailAddress"
                  onChange={(e) =>
                    handleInputChange(e)
                  }
                  required
                />
              </div>
              <div className="job-input-sections">
                <label>Feedback*</label>
                <textarea
                  placeholder="Eg: Multigrad app is a community based edtech platform"
                  value={formData.userFeedback}
                  name="userFeedback"
                  onChange={(e) =>
                    handleInputChange(e)
                  }
                  required
                />
              </div>
              <button type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </Container>
  )
};

export default Support;
