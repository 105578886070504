import React, { useEffect } from 'react';
import Arrow from '../../../assets/2.0/media/arrow.svg';
import LogoLight from '../../../assets/2.0/media/logo_white.svg';
import chat from '../../../assets/2.0/media/chat.svg';
import x from '../../../assets/2.0/media/x.svg';
import i from '../../../assets/2.0/media/i.svg';
import f from '../../../assets/2.0/media/f.svg';
import y from '../../../assets/2.0/media/y.svg';

const Footer2 = () => {

  return (
    <footer>
         
         <div className='footer end'>
          <div className='container'>
            <div className='flex-row'>
              <div className='footer-brand'>
                 <img src={LogoLight} alt="multigrad.in" />
                 <div className='footer-social'>
                    <a href="https://twitter.com/multigrad_in" target='_blank'><img src={x} alt="multigrad.in" /></a>
                    <a href="https://www.facebook.com/multigrad" target='_blank'><img src={f} alt="multigrad.in" /></a>
                    <a href="https://instagram.com/multigrad_app" target='_blank'><img src={i} alt="multigrad.in" /></a>
                    <a href="https://www.youtube.com/@multigrad" target='_blank'><img src={y} alt="multigrad.in" /></a>
                  
                 </div>
                 
              </div>
              
              <div className='footer-menu'>
                 <div className='footer-menu-element'>
                    <h2>Product</h2>
                    <a href='https://play.google.com/store/apps/details?id=com.multigrad.app' target='_blank'>Refer a friend <img src={Arrow}/></a>
                    <a href='https://www.linkedin.com/company/multigrad-app/' target='_blank'>Linkedin page <img src={Arrow}/></a>
                    <a href='https://instagram.com/multigrad_app' target='_blank'>Instagram page <img src={Arrow}/></a>
                    <a href='https://www.youtube.com/@multigrad' target='_blank'>Official youtube <img src={Arrow}/></a>

                 </div>
                 <div className='footer-menu-element'>
                    <h2>Company</h2>
                    <a href='https://medium.com/@multigradapp'  target='_blank'>Blog <img src={Arrow}/></a>
                    <a href='/internship'>Work</a>
                    <a href='/become-a-leader' target='_blank'>Become a leader</a>
                    <a href='https://www.linkedin.com/company/multigrad-app/jobs/' target='_blank'>Career <img src={Arrow}/></a>
                 </div>
                 <div className='footer-menu-element'>
                    <h2>Multigrad for</h2>
                    <a href='/mentorship'>Mentors</a>
                    <a href='https://play.google.com/store/apps/details?id=com.multigrad.app' target='_blank'>College students <img src={Arrow}/></a>
                    <a href='https://jndxbzad0kc.typeform.com/to/ENfdyQeL?typeform-source=localhost' target='_blank'>Companies <img src={Arrow}/></a>
                    <a href='https://hub.multigrad.in/' target='_blank'>Collges/Schools <img src={Arrow}/></a>

                 </div>
                 <div className='footer-menu-element'>
                    <h2>Resources</h2>
                    <a href='https://jndxbzad0kc.typeform.com/to/a1p1ya26' target='_blank'>Organise workshop  <img src={Arrow}/></a>
                    <a href='https://jndxbzad0kc.typeform.com/to/G3YNggFO' target='_blank'>Organise hackathon <img src={Arrow}/></a>
                    <a href='https://jndxbzad0kc.typeform.com/to/ENfdyQeL' target='_blank'>Job drive <img src={Arrow}/></a>
                    <a href='https://join.slack.com/t/multigrad/shared_invite/zt-2g0w3eotj-GxpH8ZPKLKop2IBE3lHlgQ' target='_blank'>Join Slack Community <img src={Arrow}/></a>
                    <a href='https://chat.whatsapp.com/KSZ38cardc5DwGCRqkebmI' target='_blank'>Join Whatsapp <img src={Arrow}/></a>
                    <a href='' target='_blank'>Certification Program <img src={Arrow}/></a>
                    
                 </div>


                 <div className='footer-menu-element'>
                    <h2>Integration</h2>
                    <a href='' target='_blank'>Developer apis <img src={Arrow}/></a>
                    <a href='https://github.com/orgs/multigrad/discussions/categories/report-a-bug' target='_blank'>Report a bug <img src={Arrow}/></a>
                    <a href='https://github.com/orgs/multigrad/discussions/categories/feedback' target='_blank'>Feedback <img src={Arrow}/></a>
                    
                   
                 </div>
              </div>
            </div>
          </div>
         </div>
         <div className='footer end-t' >
          <div className='container'>
             <div className='copyright'>
                <a>© 2022 - 2024 FightAge Private Ltd. All rights reserved. </a>
                <a href='/privacy-policy'>Privacy policy <img src={Arrow}/></a>
                <a href='/terms-and-condition'>Terms & conditions <img src={Arrow}/></a>

             </div>
          </div>
         </div>
     <div className='chat-btn'>
         <button>
            <img src={chat} />
         </button>
     </div>
    </footer>
  );
};

export default Footer2;