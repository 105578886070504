import React from 'react';
import { NavLink } from 'react-router-dom'; 
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import LogoLight from '../../../assets/2.0/media/logo_white.svg';
import Menu from '../../../assets/2.0/media/menu.svg';


const Header2 = () => {
  const handleStartFree = () => {
    window.open("https://calendly.com/amnshrma/30min?back=1&month=2024-05", '_blank');
};
const handleNavLinkClick = () => {
  window.location.reload(); // Refresh the page
};
  return (
    <>
      {/* Desktop Navbar */}
      <Navbar bg="" expand="lg" className='nav2 desktop-navbar'>
        <Container id="navTop">
          <Navbar.Brand href="/">
            <img src={LogoLight} alt="multigrad.in" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              navbarScroll
            >
              <NavLink to="/" exact className="nav-link" activeClassName="active">
                Home
              </NavLink>
              <NavLink to="/pricing" className="nav-link" activeClassName="active">
                Pricing
              </NavLink>
              <NavLink to="/internship" className="nav-link" activeClassName="active">
                Clubs
              </NavLink>
              <NavLink to="/mentorship" className="nav-link" activeClassName="active">
                Mentors
              </NavLink>
              <NavLink to="/become-a-leader" className="nav-link" activeClassName="active">
                Become a leader <span id="new">Apply</span>
              </NavLink>
            </Nav>
            <div className="d-flex header-btn">
              <a target='_blank' id="secondary-btn">Login</a>
              <button id="primary-btn">Book A Demo</button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className='comingsoon'>
           <p>Blockchain India summit  2024 | Founder Talk</p>
           <button onClick={handleStartFree}>Participate now</button>
        </div>
      <Navbar bg="" expand="lg" className='nav2 mobile-navbar'>
       
        <Container id="navTop">
          <Navbar.Brand href="/">
            <img src={LogoLight} alt="multigrad.in" /> <span id="beta">Beta</span>
          </Navbar.Brand>
         
          
            <Navbar.Toggle aria-controls="navbarScroll" >
               <img id="menu" src={Menu}/>
            </Navbar.Toggle >
           
      
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              navbarScroll
            >
              <NavLink to="/" exact className="nav-link" activeClassName="active">
                Home
              </NavLink>
              <NavLink to="/pricing" className="nav-link" activeClassName="active">
                Pricing <span id="new">New</span>
              </NavLink>
              <NavLink to="https://multigrad.in" className="nav-link" activeClassName="active">
                Student App
              </NavLink>
             
            
            </Nav>
            <div className="d-flex header-btn">
              {/* <a target='_blank' id="secondary-btn">Login</a> */}
              <button id="primary-btn" onClick={handleStartFree}>Book A Demo</button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header2;