import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import QRApp from "../../assets/media/qrapp.png";
const Signup = (props) => {
  const handleClose = () => {
    props.setShowSignup(false);
  };

  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = (event) => {
    let data = JSON.stringify({
      email: formData.email,
      password: formData.password,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/secure/login",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQ5NzZmYmU1MjFkYTJlMzlkYTFiNTliIn0sImlhdCI6MTY5MjIxMzIwMH0.01xCNh1aVgZ_I78ho2-MuBiFpSaRMCrmMO2O4_c2Uzc",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status === true) {
          localStorage.setItem("userToken", response.data.userToken);
          window.location.href = "/dashboard";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <div>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Login Via Email</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="email"
              placeholder="Your email address"
              autoFocus
              id="username"
              name="email"
              value={formData.email}
              onChange={handleChange}
              autoComplete="off"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please fill the username.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="password"
              placeholder="Password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              autoComplete="off"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please fill the password.
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            variant="danger"
            type="submit"
            className="col-12 customPopupBtn"
            onClick={handleLogin}
          >
            Login
          </Button>

          <p id="privacy">
            This site is protected by reCAPTCHA, Google Privacy Policy and Terms
            of Service apply.
          </p>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default Signup;
