import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import OverView from "../../assets/media/org.svg";
import Location from "../../assets/media/location.svg";
import { message } from 'antd';
const MyCourse = () => {
  const navigate = useNavigate();
  
  const [upload, setUploading] = useState({
    show:false,
    msg:""
  });
  const [image, setImage] = useState(null);
  const [imageRes, setImageRes] = useState("");
  const handleChangeImage = (e) => {
    setImage(e.target.files[0]);
  };


  const handleLogo = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("mediaKey", image);
    const config = {
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .post("https://api.multigrad.in/auth/media-upload", data, config)
      .then((response) => {
        setImageRes(response.data.mediaKey);
        setUploading({show:true,msg:"Uploaded"});
        message.success("Uploaded successfully!");
      })
      .catch((error) => message.error("Please try again!"));
  };

  const userToken = localStorage.getItem("userToken");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFinalImage, setSelectedFinalImage] = useState(null);
  const [formData, setFormData] = useState({
    organisationId: "",
    organisationName: "",
    organisationAddress: "",
    organisationAffiliatedWith: "",
    organisationNumber: "",
    organisationCourses: "",
    organisationType: "",
    organisationReview: "",
    organisationAbout: "",
    organisationEmail: "",
    organisationImage: "",
    organisationQrCode: "",
    organisationBannerImage: "",
    organisationGovApproved: "",
    organisationImages: "",
  });

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(
          `https://api.multigrad.in/auth/organization/dashboard`,
          {
            headers: {
              Authorization: userToken,
            },
          }
        );

        setFormData({
          ...formData,
          organisationId: response.data.organitionDetails._id,
          organisationName: response.data.organitionDetails.organisationName,
          organisationAddress:
            response.data.organitionDetails.organisationAddress,
          organisationCode: response.data.organitionDetails.organisationCode,
          organisationAffiliatedWith:
            response.data.organitionDetails.organisationAffiliatedWith,
          organisationNumber:
            response.data.organitionDetails.organisationNumber,
          organisationCourses:
            response.data.organitionDetails.organisationCourses,
          organisationType: response.data.organitionDetails.organisationType,
          organisationReview:
            response.data.organitionDetails.organisationReview,
          organisationAbout: response.data.organitionDetails.organisationAbout,
          organisationEmail: response.data.organitionDetails.organisationEmail,
          organisationImage: response.data.organitionDetails.organisationImage,
          organisationQrCode:
            response.data.organitionDetails.organisationQrCode,
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchJobData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

   
    const data = JSON.stringify({
      organisationName: formData.organisationName,
      organisationAddress: formData.organisationAddress,
      organisationAffiliatedWith: formData.organisationAffiliatedWith,
      organisationNumber: formData.organisationNumber,
      organisationCourses: formData.organisationCourses,
      organisationType: formData.organisationType,
      organisationAbout: formData.organisationAbout,
      organisationEmail: formData.organisationEmail,
      organisationImage: selectedFinalImage,
      organisationQrCode: formData.organisationQrCode,
      organisationBannerImage: imageRes,
      organisationGovApproved: formData.organisationGovApproved,
      organisationImages: "",
    });
    console.log(data);
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/organization/${formData.organisationId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        message.success("Your account has been updated");
       

        navigate("/dashboard");
      })
      .catch((error) => {
        message.error("Please try again!");
      });
  };

  const handleImage = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("mediaKey", selectedImage);

    const config = {
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .post("https://api.multigrad.in/auth/media-upload", data, config)
      .then((response) => {
        setSelectedFinalImage(response.data.mediaKey);
      })
      .catch((error) => {});
  };

  const handleChange = (e) => {
    console.log(e.target.name,e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    /*const file = e.target.files[0];
    setSelectedImage(file);
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedFile(e.target.result);
      };

      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
    }*/
  };

  return (
    <div className="container mg-job-container">
      <div className="organisation">
        <h1>Edit {formData.organisationName}</h1>
      </div>
      <div className="organisation-overview">
        <div class="org-details">
          <img src={OverView} />
          <p>Overview</p>
        </div>
        <div className="mg-job-form">
          <form onSubmit={handleSubmit}>
            {/*<div className="job-input-sections">
              <input
                type="file"
                id="picUpload"
                onChange={handleChange}
                name="organisationImage"
                required
              />
               <button onClick={handleImage}>Upload Image</button>
              <input
                type="file"
                id="picUpload"
                onChange={handleChange}
                name="organisationImage"
                required
              />
              {/* <img
                id="orgImg"
                src={formData.organisationImage}
                alt="Selected Image"
              /> 
  </div>*/}

            <div className="job-input-sections">
              <label>Organisation Name*</label>
              <input
                type="text"
                placeholder="Eg: Software Engineer"
                value={formData.organisationName}
                onChange={handleChange}
                name="organisationName"
                required
              />
            </div>

            <div className="job-input-sections">
              <label>Description *</label>
              <input
                type="text"
                placeholder=""
                value={formData.organisationAbout}
                onChange={handleChange}
                name="organisationAbout"
              />
            </div>
            <div className="job-input-sections">
            <label>Banner Image</label>
            </div>
            <div className="job-input-sections uploadbanner">
            
              <input className="pb-5"
                type="file"
                placeholder=""
                onChange={handleChangeImage}
                name="mediaKey"
              />
              <button className="m-1" onClick={handleLogo}>Upload</button>
             
            </div>
          </form>
        </div>
      </div>
      <div className="organisation-overview">
        <div class="org-details">
          <img src={OverView} />
          <p>Other Details</p>
        </div>
        <div className="mg-job-form">
          <form onSubmit={handleSubmit}>
            <div className="job-input-sections">
              <label>Organisation Address*</label>
              <input
                type="text"
                placeholder="Eg: Delhi"
                value={formData.organisationAddress}
                onChange={handleChange}
                name="organisationAddress"
              />
            </div>

            <div className="job-input-sections">
              <label>Affiliated With</label>
              <input
                type="text"
                placeholder="Eg: Delhi"
                value={formData.organisationAffiliatedWith}
                onChange={handleChange}
                name="organisationAffiliatedWith"
              />
            </div>

            <div className="job-input-sections">
              <label>Official Number *</label>
              <input
                type="number"
                placeholder=""
                value={formData.organisationNumber}
                onChange={handleChange}
                name="organisationNumber"
              />
            </div>

            <button type="submit">Final Changes</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyCourse;
