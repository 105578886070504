import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Rocket from '../../../assets/2.0/media/rocket.svg';
import { CiCalendarDate } from "react-icons/ci";
import { BiCurrentLocation } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { MdOutlineWorkOutline } from "react-icons/md";

const Mentors = () => {
  const [hackathons, setHackathons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.multigrad.in/open/mentors');
        setHackathons(response.data.data);
      } catch (error) {
        console.error('Error fetching hackathons:', error);
      }
    };

    fetchData();
  }, []);

  const openWhatsAppGroup = () => {
    window.open('https://play.google.com/store/apps/details?id=com.multigrad.app', '_blank');
  };

  return (
    <>
      <div className='upcoming'>
        {hackathons.map((hackathon) => {
          const startDate = new Date(hackathon.hackathonStartTime);
          const currentDate = new Date();
          const isFutureDate = startDate > currentDate;

          return (

            <>
            {hackathon.userProfile &&
            
            
            <>
            <div key={hackathon._id} className='mt-div'>
              <img src={hackathon.userProfile} alt={hackathon.fullName} />
              <h2>{hackathon.fullName}</h2>
              <div className='ct-cp'><MdOutlineWorkOutline /> {hackathon.mentorCurrentCompany}</div>
              <div className='btn'>
                {isFutureDate ? (
                  <button onClick={openWhatsAppGroup}>Participate now</button>
                ) : (
                  <button onClick={openWhatsAppGroup}>Connect</button>
                )}
              </div>
            </div>
            </>
        }
            </>
          );
        })}
      </div>
    </>
  );
};

export default Mentors;
