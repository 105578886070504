import React,{useContext} from 'react';
import Users from '../../../assets/2.0/media/userssvg.svg';
import { JobContext } from '../context/JobContext.js';
import { MdOutlineWorkOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import Com from '../../../assets/2.0/media/companies.svg';
import Start from '../common/Start.js';
import ButtonTop from '../common/Button.js';
const Pricing2 = () => {
    const jobs = useContext(JobContext);

    const talk =()=>{
        window.location.href="https://calendly.com/amnshrma/30min?back=1&month=2024-05"
    }
    const register =()=>{}
    
  return (
    <>
     <div className='home-container'>
        <div className='container line-container'>
            <div className='tagline'>
                <h1>Get<span id="white"> unlimited </span>access.</h1>
                <p>Navigate your college journey seamlessly - Explore, Learn, Placed! - Multigrad</p>
               
           
            </div>

            <div class="pricing">
    <div class="pricediv">
        <h2>Free</h2>
        <h1><span id="white">₹0</span></h1>
        <ul>
            <li>Basic features included</li>
            <li>Internship opportunities</li>
            <li>Access to online clubs</li>
            <li>Basic chatting system</li>
            <li>Upto 100 students</li>
            <li>Publish one update per day </li>
        </ul>
        <button onClick={talk}>Get Started</button>
    </div>
    <div class="pricediv">
        <h2>Pro <span id="new">-50%</span></h2>
        <h1><span id="linet">₹10k</span> <span id="white">₹5k</span><span id="small">/month</span></h1>
        <ul>
            <li>Enhanced features, such as advanced reporting and customization options</li>
            <li>Access to own subdomains</li>
            <li>Expanded internship opportunities</li>
            <li>Advanced payroll management</li>
            <li>Access to hackathons</li>
            <li>Access to workshops</li>
            <li>Advanced online result management</li>
            <li>Advanced attendance tracking</li>
            <li>Access to mentorship programs</li>
            <li>Advanced chatting system</li>
            <li>Access to online exams</li>
            <li>Advanced fees management</li>
            <li>Publish updates</li>
            <li>Publish unlimited update per day </li>
        </ul>
        <button onClick={talk}>Book a demo</button>
    </div>
    <div class="pricediv">
        <h2>Premium</h2>
        <h1><span id="white">₹10-50k</span><span id="small">/month</span></h1>
        <ul>
            <li>Full suite of features, including priority support and integrations with other systems</li>
            <li>Access own subdomains/domains</li>
            <li>Expanded internship opportunities with premium partners</li>
            <li>Comprehensive payroll management with advanced features</li>
            <li>Exclusive access to hackathons and workshops</li>
            <li>Advanced online result viewing and analysis tools</li>
            <li>Comprehensive attendance tracking with automated features</li>
            <li>Access to premium mentorship programs</li>
            <li>Advanced chatting system with AI-powered features</li>
            <li>Unlimited access to online exams with proctoring options</li>
            <li>Advanced fees management with automated reminders and billing</li>
            <li>Own server setup and data management</li>
        </ul>
        <button onClick={talk}>Book a demo</button>
    </div>
</div>

        </div>

        
      
    </div>
     <Start />
    </>
   
  );
};

export default Pricing2;
