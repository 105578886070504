import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MdOutlineWorkOutline } from "react-icons/md";

const Commun = () => {
  const [hackathons, setHackathons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.multigrad.in/open/users');
        setHackathons(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching hackathons:', error);
      }
    };

    fetchData();
  }, []);

  const openWhatsAppGroup = () => {
    window.open('https://play.google.com/store/apps/details?id=com.multigrad.app', '_blank');
  };

  return (
    <>
      <div className='commu container'>
        {hackathons.map((hackathon) => {
          const startDate = new Date(hackathon.hackathonStartTime);
          const currentDate = new Date();
          const isFutureDate = startDate > currentDate;

          return (

            <>
            
            <div key={hackathon._id} className='community-log' data-name={hackathon.fullName}>
    <img
        src={hackathon.userProfile}
        alt={hackathon.fullName}
        onError={(e) => {
            e.target.style.display = 'none';
        }}
    />
   {!hackathon.userProfile && <div>{hackathon.fullName}</div>}
</div>

        
            </>
          );
        })}
      </div>
    </>
  );
};

export default Commun;
