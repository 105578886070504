import React from 'react';

const ButtonBottom = () => {
    const handleStartFree = () => {
        window.open("/login");
    };
    const joinCommunity = () => {
        window.open("https://calendly.com/amnshrma/30min?back=1&month=2024-05",'_blank');
    };





  return (
   <>
   <div className='btn-two light'>
                         <button id="primary-btn" onClick={handleStartFree}>Book A Demo</button>
                         <button id="secondary-btn" onClick={joinCommunity}>Book a demo</button>
                </div>
   </>
  );
};

export default ButtonBottom;
