import React from 'react';

const ButtonTop = () => {
    const handleStartFree = () => {
        window.open("/login", '_blank');
    };
    const joinCommunity = () => {
      
        window.open("https://calendly.com/amnshrma/30min?back=1&month=2024-05", );
    };




  return (
   <>
    <div className='btn-two'>
                  
                </div>
   </>
  );
};

export default ButtonTop;
