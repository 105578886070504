import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import axios from "axios";
import FiltersAndActions from "./filterData";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
function Peoples() {
  const [peopleData, setPeopleData] = useState([]);
  const [error, setError] = useState(null);
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [tableData, setTableData] = useState();
  const [tableId, setTableId] = useState();

  const handleShow = (job) => {
    setShow(true);
  };

  useEffect(() => {
    // Define your API endpoint and token
    const apiUrl = "https://api.multigrad.in/auth/peoples";

    // Fetch data from the API
    axios
      .get(apiUrl, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data.data;
        setPeopleData(data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  const handleExport = (filterOption, searchTerm) => {
    // Implement export functionality based on filter and search
    console.log(
      "Exporting data with filter:",
      filterOption,
      "and search:",
      searchTerm
    );
  };

  const handlePrint = (filterOption, searchTerm) => {
    console.log(
      "Printing data with filter:",
      filterOption,
      "and search:",
      searchTerm
    );
  };

  const handleSendMessage = (person) => {
    navigate("/messaging");
  };

  const rejectMember = (item) => {
    let data = JSON.stringify({
      campusVerified: "no",
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/org-users/${item._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "error",
          title: `Rejected, Not affiliated with your organisation`,
          showConfirmButton: false,
          timer: 800,
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const approveMember = (item) => {
    let data = JSON.stringify({
      campusVerified: "yes",
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/org-users/${item._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: `Approved, Affiliated with your organisation`,
          showConfirmButton: false,
          timer: 800,
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Container>
      <FiltersAndActions
        data={peopleData}
        onExport={handleExport}
        onPrint={handlePrint}
      />
      <div className="peoples">
        {error && <p>Error: {error}</p>}
        <table>
          <thead>
            <tr>
              <th>Profile</th>
              <th>Full Name</th>
              {/* <th>Certificate Details</th> */}

              <th>User Status</th>
            </tr>
          </thead>
          <tbody>
            {peopleData.map((person, index) => (
              <tr key={index}>
                <td>
                  <img src={person.userProfile} id="profile" />
                </td>
                <td id="pD">
                  <p>{person.fullName}</p>
                  <p>{person.classOrCourse}</p>
                </td>
                {/* <td>
                  <button onClick={handleShow}>View</button>
                </td> */}

                <td id="sB">
                  {person.campusVerified === "no" && (
                    <button onClick={() => approveMember(person)}>
                      Approve
                    </button>
                  )}
                  {person.campusVerified === "yes" && (
                    <button id="rj" onClick={() => rejectMember(person)}>
                      Reject
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal show={show} onHide={handleClose} id="tableOfContent">
        <Modal.Header closeButton>
          <Modal.Title>Table of Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="certificate-table">
            <tr>
              <th>Certificate Title</th>
              <th>Category</th>
            </tr>
          </table>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Peoples;
