import React from 'react';
import Users from '../../../assets/2.0/media/userssvg.svg'
const Roadmap2 = () => {
  return (
    <div className='home-container'>
        <div className='container line-container'>
            <div className='tagline'>
                <h1><span id="white">Find your dream job</span></h1>
                <p>Navigate your college journey seamlessly - Explore, Learn, Placed! - Multigrad</p>
                <div className='btn-two'>
                    <button id="primary-btn">Book A Demo</button>
                    <button id="secondary-btn">Talk to support</button>
                </div>
                <div className='btn-two'>
                    <img src={Users}/>
                    <p>10,000+ students across india using multigrad!</p>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Roadmap2;
